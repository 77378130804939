import { useState } from "react";
import FormComponent from "../FormComponent";
import logo from "../../images/favicon.png"
import ThankYou from "../ThankYou";
export default function Envelope() {
    const [toggleFlap, setToggleFlap] = useState('');
    const [showImg, setShowImg] = useState(true)
    const handleToggle = (e) => {
        if (!e.target.classList.contains('letter') && !e.target.closest('.letter')) {
            toggleFlap == 'flap' ? setToggleFlap('') : setToggleFlap('flap')
            setShowImg(false)
        }
    }
    const [hideClass1, setHideClass1] = useState('');
    const [hideClass2, setHideClass2] = useState('hide');
    const handleSubmit = () => {
        setHideClass1('hide');
        setHideClass2('');
    }
    return (
        <>

            <div className={hideClass1}>
                <div className="container">
                    {
                        showImg &&
                        <img src={logo} className="logo"></img>
                    }
                    <div className={"envelope-wrapper " + toggleFlap} onClick={(e) => { handleToggle(e); }}>
                        <div className="envelope">
                            <div className="letter">
                                <div className="text">
                                    <FormComponent handleSubmit={handleSubmit} />
                                </div>
                            </div>
                        </div>
                        <div className="heart"></div>
                    </div>
                </div>
            </div>
            <div className={hideClass2}>
                <ThankYou />
            </div>
        </>
    )
}
export default function ThankYou() {
   
    return (
        <>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center',
                fontFamily:'virtual',
                color:'white',
                fontSize:'48px',
                lineHeight:0
            }} className="container">
                Thank You
            </div>
        </>
    )
}
import React, { useState } from 'react'
import { redirect } from 'react-router-dom'

export default function FormComponent({ handleSubmit }) {
    const [hideClass1, setHideClass1] = useState('')
    const [hideClass2, setHideClass2] = useState('hide')
    const [nameError, setNameError] = useState(false)
    const [pNameError, setPNameError] = useState(false)
    const [ageError, setAgeError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [name, setName] = useState('')
    const [age, setAge] = useState('');
    const [phone, setPhone] = useState('');
    const [pName, setPName] = useState('');
    const [location, setLocation] = useState('');
    const toggleForms = () => {
        setNameError(false)
        setPNameError(false)
        setPhoneError(false)
        setAgeError(false)

        if (!name) {
            setNameError(true)
        }
        else if (!pName) {
            setPNameError(true)
        }
        else if (!phone) {
            setPhoneError(true)
        }
        else if (!age || age < 18) {
            setAgeError(true)
        }
        else if (!location) {
            setLocationError(true)
        }
        else {
            setHideClass1('hide')
            setHideClass2('')
        }
    }

    return (
        <container>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column' }}>
                <h2 className='soulmateText'>My Soulmate</h2>
                <p style={{ fontFamily: 'Poppins', maxWidth: '300px', marginBottom: '12px', fontSize: '12px', textAlign: 'center' }}>
                    Come and grab your <b>Free Cloud Puddings</b> on Valentine’s Day at Kappa Chakka Kandhari from <b>9AM to 12 noon</b>
                </p>
            </div>
            <iframe name="dummyframe" id="dummyframe" style={{ display: 'none' }}></iframe>
            <form target='dummyframe' onSubmit={() => { handleSubmit(); }} action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSetM-4g28AcsyYGtSq-19pJzW5C36rWgw51nf0DbbpwjKFLHw/formResponse" method='post'>
                <div className={`firstForm ${hideClass1}`}>
                    <div className='form-component'>
                        <label for='name'>
                            Your Name<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            name='entry.1264919164'
                            label='Your name'
                            required
                            className='inputClass'
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                        {
                            nameError &&
                            <div>
                                <span className='errorSpan'>Please enter your name</span>
                            </div>
                        }
                    </div>
                    <div className='form-component'>
                        <label for='name'>
                            Your Partner's Name<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            name='entry.423833978'
                            label="Your Partner's Name"
                            required
                            className='inputClass'
                            value={pName}
                            onChange={(e) => { setPName(e.target.value) }}
                        />
                        {
                            pNameError &&
                            <div>
                                <span className='errorSpan'>Please enter your Partner's name</span>
                            </div>
                        }
                    </div>
                    <div className='form-component'>
                        <label for='name'>
                            Phone Number<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            name='entry.1740728250'
                            type='number'
                            label='Phone Number'
                            required
                            className='inputClass'
                            value={phone}
                            onChange={(e) => { setPhone(e.target.value) }}
                        />
                        {
                            phoneError &&
                            <div>
                                <span className='errorSpan'>Please enter your Phone Number</span>
                            </div>
                        }
                    </div>
                    <div className='form-component'>
                        <label for='name'>
                            Age<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            name='entry.1193447884'
                            type='number'
                            label='Age'
                            min={18}
                            required
                            className='inputClass'
                            value={age}
                            onChange={(e) => { setAge(e.target.value) }}
                        />
                        {
                            ageError &&
                            <div>
                                <span className='errorSpan'>Age must be 18 or above</span>
                            </div>
                        }
                    </div>
                    <div className='form-component'>
                        <label for='name'>
                            Location<span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                            name='entry.1267354871'
                            className='inputClass'
                            value={location}
                            onChange={(e) => { setLocation(e.target.value) }}
                            style={{width:'100%', fontFamily:'Poppins'}}
                        >
                            <option style={{fontFamily:'Poppins'}} hidden selected>
                                Select a Location
                            </option>
                            <option style={{fontFamily:'Poppins'}} value={'Chennai'}>
                                Chennai
                            </option>
                            <option style={{fontFamily:'Poppins'}} value={'Bengaluru'}>
                                Bengaluru
                            </option>
                        </select>
                        {
                            locationError &&
                            <div>
                                <span className='errorSpan'>Please select a location</span>
                            </div>
                        }
                    </div>
                    <div>
                        <button
                            type='button'
                            onClick={(e) => { e.preventDefault(); toggleForms() }}
                        >Get our free cloud puddings</button>
                    </div>
                </div>
                <div className={`secondFormText ${hideClass2}`}>
                    <div className='secondFormText'>
                        <p><b>And we have something more exciting!</b></p>
                        <p>You can stand a chance to win a free meal with your loved one at Kappa Chakka Kandhari.</p>
                        <p>All you have to do is follow <b>@kckfoods</b> on Instagram and Craft your definition of love in just one line below.</p>
                    </div>
                    <textarea
                        style={{ width: '90%', height: '100px', marginBottom: '12px', fontFamily: 'Poppins' }}
                        name='entry.42018624'
                        className='inputClass'
                        placeholder={"Be creative, whether it's poetic, humorous, or profound, let your personality shine through."}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <button
                            type='submit'
                            className='sfBtn'
                        >Win a free meal</button>
                        <button
                            type='submit'
                            className='sfBtn'
                        >We are good with the cloud puddings</button>
                    </div>
                </div>
            </form>
        </container>
    )
}
import logo from './logo.svg';
import './App.css';
import { FormThemeProvider } from 'react-form-component'
import FormComponent from './components/FormComponent';
import Envelope from './components/Envelope';

function App() {

  return (
    <FormThemeProvider>
      <Envelope/>
    </FormThemeProvider>

  );
}

export default App;
